<template>
  <v-dialog v-model="dialog" persistent>
    <v-form ref="form" class="overlay">
      <div class="overlayarea scroll">
        <v-card flat color="#f0f0f0" class="cardsize">
          <div class="text-right">
            <v-icon @click="closeDialog">mdi-close</v-icon>
          </div>
          <div class="subtitle">
            {{ $t("PAGE_TITLE_CARD_SEARCH") }}
            <HelpButton :helpobject="searchHelpDialogData"></HelpButton>
          </div>

          <!-- 検索機能 -->
          <CardSearchDialogSearchCondition :dialog="dialog" @condition="condition = $event" class="mx-4 my-1" />

          <v-data-table
            v-model="selected"
            class="datatable scroll"
            :headers="inCompleteHeaders"
            :items="inCompleteItems"
            item-key="tileId"
            show-select
            dense
            :footer-props="TABLE_FOOTER_SETTING_XLONG"
          >
            <template v-slot:[`item.posCol`]="{ item }">
              {{ item.posCol === null ? wordUnplanned : item.posCol }}
            </template>
            <template v-slot:[`item.lineName`]="{ item }">
              {{ item.posCol === null ? "" : item.lineName }}
            </template>
            <template v-slot:[`item.order`]="{ item }">
              {{ item.posCol === null ? "" : item.order }}
            </template>
            <template v-slot:[`item.isComplete`]="{ item }">
              <v-checkbox dense hide-details readonly class="my-0" v-model="item.isComplete"></v-checkbox>
            </template>
            <template v-slot:[`item.isConfirmed`]="{ item }">
              <v-checkbox dense hide-details readonly class="my-0" v-model="item.isConfirmed"></v-checkbox>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ createDateTime(item.createdAt) }}
            </template>
            <template v-slot:[`item.changeDate`]="{ item }">
              <div v-if="item.posCol">
                <v-icon small class="mr-2" @click="moveToTargetDate(item.posCol)">
                  mdi-arrow-right-thick
                </v-icon>
              </div>
            </template>
          </v-data-table>
          <div class="areamargin">
            <v-btn class="info ml-1" :disabled="checkItemsLength || !authInCompleteAction" @click="openMoveCardDialog()">{{ $t("WORD_MOVE_CARD") }}</v-btn>
            <v-btn v-if="option002" class="secondary ml-1" :disabled="checkItemsLength || !authInCompleteAction" @click="confirmTile">{{ $t("WORD_UPDATE_CONFIRM") }}</v-btn>
            <v-btn class="secondary ml-1" :disabled="checkItemsLength || !authInCompleteAction" @click="completeTile">{{ $t("WORD_UPDATE_COMPLETE") }}</v-btn>
            <v-btn class="error ml-1" :disabled="checkItemsLength || !authInCompleteAction" @click="deleteIncompleteTile">{{ $t("WORD_UPDATE_DELETE") }}</v-btn>
          </div>
        </v-card>
        <CardSearchDialogMoveCard
          :dialog="isMoveCard"
          :selected="selected"
          @close="callbackMoveCardDialogClose"
        />
      </div>
    </v-form>
  </v-dialog>
</template>

<script>
import Define from "@/define.js";
import Swal from "sweetalert2";
import * as Util from "@/util/utils.js";
import moment from "moment";
import CardSearchDialogSearchCondition from "@/components/parts/CardSearchDialogSearchCondition";
import CardSearchDialogMoveCard from "@/components/parts/CardSearchDialogMoveCard";
import HelpButton from "@/components/parts/HelpButton";
export default {
  name: "CardSearchDialog",
  components: {
    CardSearchDialogSearchCondition,
    CardSearchDialogMoveCard,
    HelpButton
  },
  data() {
    return {
      selected: [],
      isMoveCard: false,
      condition: null
    };
  },
  computed: {
    dialog() {
      return this.$store.getters["tile/isOpenCardSearch"];
    },
    inCompleteItems() {
      let items = this.$store.getters["tile/inCompleteItems"](this.condition);
      for (let item of items) {
        item.lineName = this.areaName(item.lineName);
      }
      return items;
    },
    inCompleteHeaders() {
      let fixedHeaders = [
        { text: this.$t("TABLE_CARD_SEARCH_POSCOL"), value: 'posCol', width: '110px' },
        { text: this.$t("TABLE_CARD_SEARCH_LINENAME"), value: 'lineName', width: '200px', sortable: false },
        { text: this.$t("TABLE_CARD_SEARCH_ORDER"), value: 'order', width: '110px', sortable: false }
      ];
      if(this.option002){
        fixedHeaders.push({ text: this.$t("TABLE_CARD_SEARCH_CONFIRM"), value: 'isConfirmed', width: '80px', sortable: false });
      }
      fixedHeaders.push({ text: this.$t("TABLE_CARD_SEARCH_COMPLETE"), value: 'isComplete', width: '80px', sortable: false });
      let headers = this.$store.getters["tile/inCompleteHeaders"](this.condition);
      headers = headers ? headers.map(d => { return { text: this.mappingName(d.text), value: d.value } }) : [];
      headers.push({ text: this.$t("TABLE_CARD_SEARCH_CREATEDAT"), value: "createdAt", width: '200px' });
      headers.push({ text: "", value: "changeDate", width: '60px' });
      return fixedHeaders.concat(headers);
    },
    authInCompleteAction() {
      let user = this.$store.getters["auth/user"];
      return Util.getAuthority(Define.EVENT_INCOMPLETE_ACTION, user.role);
    },
    checkItemsLength() {
      let items = this.$store.getters["tile/inCompleteItems"];
      return items.length == 0;
    },
    getMappingList() {
      return this.$store.getters["setting/getMappingList"];
    },
    notifyFlgUpdate() {
      return this.$store.getters["notifyFlgUpdate"];
    },
    notifyFlgDelete() {
      return this.$store.getters["notifyFlgDelete"];
    },
    option002() {
      let option = this.$store.getters["option/getOption"];
      return option.option002;
    },
    wordUnplanned() {
      return this.$t("WORD_UNPLANNED");
    },
    TABLE_FOOTER_SETTING_XLONG() {
      return this.$t("TABLE_FOOTER_SETTING_XLONG");
    },
    searchHelpDialogData() {
      return this.$t("EXPLANATION_DIALOG.searchHelp");
    }
  },
  watch: {
    notifyFlgUpdate: function() {
      if (this.dialog) {
        this.selected = [];
      }
    },
    notifyFlgDelete: function() {
      if (this.dialog) {
        this.selected = [];
      }
    }
  },
  methods: {
    /**
     * マッピングIDからデータ名称文字列を返す
     * @param {String} id - マッピングID
     * @returns {String}} - データ名称
     */
    mappingName(id) {
      if (!id) return "";
      return this.$store.getters["setting/getMappingName"](id);
    },
    /**
     * エリアIDからエリア名称文字列を返す
     * @param {String} id - マッピングID
     * @returns {String}} - データ名称
     */
    areaName(id) {
      if (!id) return this.$t("WORD_NOTSET");
      return this.$store.getters["setting/getAreaName"](id);
    },
    /**
     * カード一括移動ダイアログを開く
     */
    openMoveCardDialog() {
      this.isMoveCard = true;
    },
    /**
     * チェックしたカードを完了状態にする
     */
    completeTile() {
      Swal.fire({
        icon: "question",
        html: this.$t("MSG_SEARCH_COMPLETE_CARD"),
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          // 選択したカードから、既に未計画のカード、完了状態のカードを除外する
          let filterdSelected = this.selected.filter(d => d.posCol !== null && d.isComplete === false);
          if (filterdSelected.length === 0) {
            Swal.fire(this.$t("MSG_ERR_TARGET_NOT_EXIST"));
            return;
          }
          // 50枚以上のカードを完了状態にしようとすると、エラーメッセージを表示する
          if (filterdSelected.length > Define.CARD_OPERATION_MAX_NUM) {
            Swal.fire(this.$t("MSG_ERR_CARD_OPERATION_MAX_NUM"));
            return;
          }
          let completeTiles = [];
          for (let i = 0; i < filterdSelected.length; i++) {
            let completeTile = {
              tile_id: filterdSelected[i].tileId,
              pos_col: filterdSelected[i].posCol,
              pos_row: filterdSelected[i].posRow,
              order: filterdSelected[i].order,
              is_complete: true,
              color: filterdSelected[i].color,
              version: filterdSelected[i].version,
              is_confirmed: filterdSelected[i].isConfirmed,
              status: filterdSelected[i].status
            }
            if (!filterdSelected[i].isComplete) {
              completeTile.tile_complete_history_data = {
                history_type: Define.HISTORY_TYPE_COMPLETE,
                flg_type: Define.FLG_TYPE_COMPLETE,
                flg_before: false,
                flg_after: true
              }
            }
            completeTiles.push(completeTile);
          }
          this.$store.dispatch("tile/detailUpdateTile", { tileData: completeTiles });
        }
      });
    },
    /**
     * チェックしたカードを確定状態にする
     */
    confirmTile() {
      Swal.fire({
        icon: "question",
        html: this.$t("MSG_SEARCH_CONFIRM_CARD"),
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          // 選択したカードから、既に未計画のカード、確定状態のカードを除外する
          let filterdSelected = this.selected.filter(d => d.posCol !== null && d.isConfirmed === false);
          if (filterdSelected.length === 0) {
            Swal.fire(this.$t("MSG_ERR_TARGET_NOT_EXIST"));
            return;
          }
          // 50枚以上のカードを確定状態にしようとすると、エラーメッセージを表示する
          if (filterdSelected.length > Define.CARD_OPERATION_MAX_NUM) {
            Swal.fire(this.$t("MSG_ERR_CARD_OPERATION_MAX_NUM"));
            return;
          }
          let confirmTiles = [];
          for (let i = 0; i < filterdSelected.length; i++) {
            let confirmTile = {
              tile_id: filterdSelected[i].tileId,
              pos_col: filterdSelected[i].posCol,
              pos_row: filterdSelected[i].posRow,
              order: filterdSelected[i].order,
              is_complete: filterdSelected[i].isComplete,
              color: filterdSelected[i].color,
              version: filterdSelected[i].version,
              is_confirmed: true,
              status: Define.CARD_STATUS_CONFIRMED
            }
            if (!filterdSelected[i].isConfirmed) {
              confirmTile.tile_confirmed_history_data = {
                history_type: Define.HISTORY_TYPE_CONFIRMED,
                flg_type: Define.FLG_TYPE_CONFIRMED,
                flg_before: false,
                flg_after: true
              }
            }
            confirmTiles.push(confirmTile);
          }
          this.$store.dispatch("tile/detailUpdateTile", { tileData: confirmTiles });
        }
      });
    },
    /**
     * チェックしたカードを削除する
     */
    deleteIncompleteTile() {
      Swal.fire({
        icon: "question",
        html: this.$t("MSG_SEARCH_DELETE_CARD"),
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          let deleteTiles = [];
          for (let i = 0; i < this.selected.length; i++) {
            deleteTiles.push({
              tile_id: this.selected[i].tileId
            });
          }
          // 50枚以上のカードを削除しようとすると、エラーメッセージを表示する
          if (deleteTiles.length > Define.CARD_OPERATION_MAX_NUM) {
            Swal.fire(this.$t("MSG_ERR_CARD_OPERATION_MAX_NUM"));
            return;
          }
          this.$store.dispatch("tile/deleteTile", deleteTiles);
        }
      });
    },
    closeDialog() {
      this.$store.commit("tile/setIsOpenCardSearch", false);
    },
    /**
     * 日付を指定のフォーマットの文字列に変換して返す
     * @param {Date} date - 更新履歴日付
     * @returns {String} - 整形後日付の文字列
     */
    createDateTime(date) {
      let day = Define.DATE_FORMAT_DAY_SLASH;
      let time = Define.DATE_FORMAT_TIME_HM;
      let format = `${day} - ${time}`;
      return moment
        .utc(date)
        .local()
        .format(format);
    },
    /**
     * 指定日に移動する
     */
    moveToTargetDate(value) {
      this.$emit("changeDate", value);
      this.closeDialog();
    },
    /**
     * カード一括移動ダイアログを閉じる
     */
    callbackMoveCardDialogClose() {
      this.isMoveCard = false;
    },
  }
};
</script>

<style scoped>
.overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.overlayarea {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.cardsize {
  margin-top: 20px;
  width: 1700px;
  max-height: 900px;
}
.datatable {
  margin-left: auto;
  margin-right: auto;
  height: 615px;
  max-height: 630px;
  width: 98%;
}
.text-right {
  position: relative;
  top: 10px;
  right: 10px;
}
.subtitle {
  font-size: 120%;
  border-left: solid 6px #1976d2ca;
  color: #333;
  padding: 0 10px;
  margin-left: 6px;
}
.areamargin {
  text-align: right;
  margin: 10px 10px 10px 10px;
}
</style>
