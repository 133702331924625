var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("v-form", { ref: "form", staticClass: "overlay" }, [
        _c(
          "div",
          { staticClass: "overlayarea scroll" },
          [
            _c(
              "v-card",
              {
                staticClass: "cardsize",
                attrs: { flat: "", color: "#f0f0f0" }
              },
              [
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c("v-icon", { on: { click: _vm.closeDialog } }, [
                      _vm._v("mdi-close")
                    ])
                  ],
                  1
                ),
                _c("div", { staticClass: "subtitle" }, [
                  _vm._v(" " + _vm._s(_vm.$t("PAGE_TITLE_CARD_HISTORY")) + " ")
                ]),
                _c("CardHistoryDialogSearchCondition", {
                  staticClass: "mx-4 my-3",
                  attrs: { dialog: _vm.dialog }
                }),
                _c(
                  "v-card",
                  { staticClass: "mx-4", attrs: { flat: "" } },
                  [
                    _c("v-data-table", {
                      staticClass: "datatable scroll",
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.items,
                        "item-key": "tileId",
                        dense: "",
                        "footer-props": _vm.TABLE_FOOTER_SETTING_LONG
                      },
                      on: { "click:row": _vm.rowClick },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.posCol",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.posCol === null
                                        ? _vm.wordUnplanned
                                        : item.posCol
                                    ) +
                                    " "
                                )
                              ]
                            }
                          },
                          {
                            key: "item.lineName",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.posCol === null ? "" : item.lineName
                                    ) +
                                    " "
                                )
                              ]
                            }
                          },
                          {
                            key: "item.createdAt",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.createDateTime(item.createdAt)) +
                                    " "
                                )
                              ]
                            }
                          },
                          {
                            key: "item.changeDate",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                item.posCol
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: { small: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.moveToTargetDate(
                                                  item.posCol
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" mdi-arrow-right-thick ")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                ),
                _c("CardDetailHistory", {
                  staticClass: "history-area",
                  attrs: { isModal: true }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }