<template>
  <v-dialog v-model="dialog" persistent>
    <v-form ref="form" class="overlay">
      <div class="overlayarea scroll">
        <v-card flat color="#f0f0f0" class="cardsize">
          <div class="text-right">
            <v-icon @click="closeDialog">mdi-close</v-icon>
          </div>
          <div class="subtitle">
            {{ $t("PAGE_TITLE_CARD_HISTORY") }}
          </div>

          <!-- 検索機能 -->
          <CardHistoryDialogSearchCondition :dialog="dialog" class="mx-4 my-3" />

          <!-- カード一覧 -->
          <v-card flat class="mx-4">
            <v-data-table
              class="datatable scroll" 
              :headers="headers" 
              :items="items" 
              item-key="tileId" 
              dense
              :footer-props="TABLE_FOOTER_SETTING_LONG" 
              @click:row="rowClick"
            >
              <template v-slot:[`item.posCol`]="{ item }">
                {{ item.posCol === null ? wordUnplanned : item.posCol }}
              </template>
              <template v-slot:[`item.lineName`]="{ item }">
                {{ item.posCol === null ? "" : item.lineName }}
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ createDateTime(item.createdAt) }}
              </template>
              <template v-slot:[`item.changeDate`]="{ item }">
                <div v-if="item.posCol">
                  <v-icon small class="mr-2" @click="moveToTargetDate(item.posCol)">
                    mdi-arrow-right-thick
                  </v-icon>
                </div>
              </template>
            </v-data-table>
          </v-card>

          <!-- カード履歴 -->
          <CardDetailHistory class="history-area" :isModal="true" />

        </v-card>
      </div>
    </v-form>
  </v-dialog>
</template>

<script>
import Define from "@/define.js";
import moment from "moment";
import CardHistoryDialogSearchCondition from "@/components/parts/CardHistoryDialogSearchCondition.vue";
import CardDetailHistory from "./CardDetailHistory.vue";
export default {
  name: "CardHistoryDialog",
  components: {
    CardHistoryDialogSearchCondition,
    CardDetailHistory
  },
  computed: {
    dialog() {
      return this.$store.getters["tile/isOpenHistory"];
    },
    headers() {
      let fixedHeaders = [
        { text: this.$t("TABLE_CARD_SEARCH_UPDATEDAT"), value: "updatedAt", width: '170px' },
        { text: this.$t("TABLE_CARD_SEARCH_POSCOL"), value: 'posCol', width: '120px' },
        { text: this.$t("TABLE_CARD_SEARCH_LINENAME"), value: 'lineName', width: '120px', sortable: false },
      ];
      let headers = this.$store.getters["tile/inCompleteHeaders"](this.condition);
      headers = headers ? headers.map(d => { return { text: this.mappingName(d.text), value: d.value } }) : [];
      headers.push({ text: "", value: "changeDate", width: '60px' });
      return fixedHeaders.concat(headers);
    },
    items() {
      let items = this.$store.getters["tile/inCompleteItems"](this.condition);
      for (let item of items) {
        item.lineName = this.areaName(item.lineName);
      }
      //itemsをupdatedAtの降順でソートする
      items.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      
      return items;
    },
    wordUnplanned() {
      return this.$t("WORD_UNPLANNED");
    },
    TABLE_FOOTER_SETTING_LONG() {
      return this.$t("TABLE_FOOTER_SETTING_LONG");
    },
  },
  watch: {
    dialog: {
      immediate: true,
      handler: function(value) {
        if (value) {
          this.$store.dispatch("tile/getTileMoveHistoryData", null);
        }
      }
    }
  },
  methods: {
    mappingName(id) {
      if (!id) return "";
      return this.$store.getters["setting/getMappingName"](id);
    },
    areaName(id) {
      if (!id) return this.$t("WORD_NOTSET");
      return this.$store.getters["setting/getAreaName"](id);
    },
    createDateTime(date) {
      let day = Define.DATE_FORMAT_DAY_SLASH;
      let time = Define.DATE_FORMAT_TIME_HM;
      let format = `${day} - ${time}`;
      return moment
        .utc(date)
        .local()
        .format(format);
    },
    rowClick(item){
      this.$store.dispatch("tile/getTileMoveHistoryData", item.tileId);
    },
    moveToTargetDate(value) {
      this.$emit("changeDate", value);
      this.closeDialog();
    },
    closeDialog() {
      this.$store.commit("tile/setIsOpenHistory", false);
    }
  }
};
</script>

<style scoped>
.overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.overlayarea {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.cardsize {
  margin-top: 20px;
  width: 1700px;
  max-height: 900px;
}
.datatable {
  height: 300px;
  max-height: 300px;
  border: solid 1px #aaa;
}
.text-right {
  position: relative;
  top: 10px;
  right: 10px;
}
.subtitle {
  font-size: 120%;
  border-left: solid 6px #1976d2ca;
  color: #333;
  padding: 0 10px;
  margin-left: 6px;
}
.scroll {
  overflow: auto;
  white-space: nowrap;
}
.history-area {
  margin: 5px 10px 10px 10px;
}
</style>
