<template>
  <!-- 検索条件 -->
  <v-card flat rounded>
    <v-form ref="form">
      <div class="pt-0 mt-0">
        <v-row dense>

          <!-- 一列目 -->
          <v-col cols="6" class="mx-1">
            <!-- 更新日時 -->
            <v-row dense align="center">
              <v-col cols="2" class="condition-title">{{ $t("TABLE_CARD_SEARCH_UPDATEDAT") }}</v-col>
              <v-col style="height: 48px;">
                <v-row dense align="center">
                  <EditingItem class="mr-0" style="width:140px;" v-model="condition.startUpdatedDate" :type="TYPE_DATE" :rules="[]" :hideDetails="true" />
                  <EditingItem class="mr-1" style="width:50px;" v-model="condition.startUpdatedTime" :type="TYPE_TIME" :rules="[]" :hideDetails="true" />
                </v-row>
              </v-col>
              ～
              <v-col style="height: 48px;">
                <v-row dense align="center">
                  <EditingItem class="ml-1" style="width:140px;" v-model="condition.endUpdatedDate" :type="TYPE_DATE" :rules="[]" :hideDetails="true" />
                  <EditingItem class="ml-0" style="width:50px;" v-model="condition.endUpdatedTime" :type="TYPE_TIME" :rules="[]" :hideDetails="true" />
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <!-- 二列目 -->
          <v-col cols="3" class="mx-5" v-if="option002">
            <v-row dense align="center">
              <v-col cols="2" class="condition-title">{{ $t("PAGE_SUBTITLE_CARD_SEARCH_CONFIRM") }}</v-col>
              <v-col style="height: 48px;">
                <v-checkbox hideDetails class="checkbox" v-model="condition.status" :label="$t('WORD_ONLY_CHANGE_AFTER_CONFIRM')" value="2" false-value=""></v-checkbox>
              </v-col>
            </v-row>
          </v-col>

          <!-- 三列目 -->
          <v-col cols="2" class="mx-5">
            <v-row dense align="center" class="mt-1">
              <v-btn width="202px" class="info" @click="search()">
                <v-icon>mdi-magnify</v-icon>
                {{ $t("WORD_SEARCH") }}
              </v-btn>
            </v-row>
          </v-col>

        </v-row>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import Define from "@/define.js";
import Swal from "sweetalert2";
import moment from "moment";
import EditingItem from "@/components/parts/EditingItem";
import { mixinValidator } from "@/mixins/mixinValidator.js";
export default {
  name: "CardHistoryDialogSearchCondition",
  components: {
    EditingItem
  },
  mixins: [mixinValidator],
  props: {
    dialog: Boolean
  },
  data() {
    return {
      condition: {},
      TYPE_DATE: Define.TYPE_DATE,
      TYPE_TIME: Define.TYPE_TIME,
    };
  },
  computed: {
    option002() {
      let option = this.$store.getters["option/getOption"];
      return option.option002;
    }
  },
  watch: {
    dialog: {
      immediate: true,
      handler: function(value) {
        if (value) {
          this.condition = {
            cardArea: "",
            startDate: "",
            endDate: "",
            area: "",
            startCreatedDate: "",
            startCreatedTime: "",
            endCreatedDate: "",
            endCreatedTime: "",
            startUpdatedDate: moment().format(Define.DATE_FORMAT_DAY_SLASH_CHECK),
            startUpdatedTime: "",
            endUpdatedDate: "",
            endUpdatedTime: "",
            isCompletedTrue: "",
            isCompletedFlase: "",
            isConfirmedTrue: "",
            isConfirmedFlase: "",
            status: "",
            detailMappingId: "",
            detailValue: ""
          };
          this.$store.dispatch("tile/serchCard", this.condition);
        }
      }
    }
  },
  methods: {
    search() {
      if (!this.$refs.form.validate()) {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_MAIN_INPUT"),
          text: this.$t("MSG_ERR_SUB_INPUT")
        });
        return;
      }
      this.condition.status = this.condition.status != null ? this.condition.status : "";
      this.$store.dispatch("tile/getTileMoveHistoryData", null);
      this.$store.dispatch("tile/serchCard", this.condition);
    }
  }
};
</script>

<style scoped>
.condition-title {
  color: #666;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}
.search-btn-title {
  color: #999;
  font-size: 14px;
  margin-left: 5px;
}
.checkbox {
  margin-top: 5px;
}
</style>
