<template>
  <div class="pagestemplate">
    <div class="importfilepage">
      <PageTitle
        :titles="pagetitle"
        :descriptions="pagedescription"
      ></PageTitle>
      <div>
        <v-stepper v-model="stepperPageNum" class="stepper_width">
          <v-stepper-header class="header_color">
            <!-- ステッパー - ヘッダー部 -->
            <v-stepper-step :complete="stepperPageNum > 1" step="1" color="info">
              <span class="">{{ $t("TABNAME_INPUT_FILE_SELECT") }}</span>
            </v-stepper-step>
            <v-divider class=""></v-divider>
            <v-stepper-step :complete="stepperPageNum > 2" step="2" color="info">
              <span class="">{{ $t("TABNAME_INPUT_FILE_SETTING") }}</span>
            </v-stepper-step>
            <v-divider class=""></v-divider>
            <v-stepper-step step="3" color="info">
              <span class="">{{ $t("TABNAME_INPUT_FILE_START") }}</span>
            </v-stepper-step>
          </v-stepper-header>
          <!-- ステッパー - ボディー部 -->
          <v-stepper-items class="content mt-2">
            <!-- ステップ１ ファイル選択 -->
            <v-stepper-content step="1">
              <div class="text-center contents_area">
                <div class="text_area">
                  <div class="button_center mt-12">
                    <v-btn
                      x-large
                      width="40vw"
                      color="success"
                      dark
                      @click="fileUpload"
                      :disabled="!authFileInput"
                      ><v-icon right dark class="mr-2">
                        mdi-file-document-outline</v-icon
                      >
                      &nbsp;{{ $t("WORD_INPUT_FILE_SELECT_FILE") }}
                    </v-btn>
                    <div>
                      <p class="mt-3 mb-0">
                        {{ $t("WORD_INPUT_FILE_SELECTED_FILE") }} {{ fileName || fileNameNotSet }}
                      </p>
                      <p v-show="alertFlg" class="my-0">
                        <span class="mt-1 alert_text_color">{{ $t("MSG_INPUT_FILE_SELECT_ALERT") }}</span>
                      </p>
                      <div v-show="!alertFlg" class="pt-6">&nbsp;</div>
                    </div>
                    <div class="text-left mt-4">
                      <div class="border_left mb-3">{{ $t("WORD_INPUT_FILE_ATTENTION") }}</div>
                      <ul v-for="(attention, index) in attentions" :key="index">
                        <li class="linefeed mb-1">{{ attention }}</li>
                      </ul>
                    </div>
                    <input
                      v-show="false"
                      id="file_input_expense"
                      ref="inputUpload"
                      name="file_input_expense"
                      type="file"
                      accept=".csv"
                      @change="fileChange"
                    />
                  </div>
                </div>
                <div class="button_area text-right">
                  <v-btn color="info" @click="fileCheck">{{ $t("WORD_NEXT") }}</v-btn>
                </div>
              </div>
            </v-stepper-content>
            <!-- ステップ２ 取り込み設定 -->
            <v-stepper-content step="2">
              <div class="contents_area">
                <div class="areatitle">
                  {{ $t("PAGE_TITLE_INPUT_FILE_SETTING") }}
                  <HelpButton :helpobject="fileHelpDialogData"></HelpButton>
                </div>
                <InputFileSetting
                  :inputFileSetting="InputFileSetting"
                  :userRole="!authFileInput"
                  :mappingDataList="mappingList"
                  @input="importSetting = $event"
                ></InputFileSetting>
                <div class="d-flex justify-space-between mt-4">
                  <v-btn @click="stepperPageNum = 1">{{ $t("WORD_BACK") }}</v-btn>
                  <v-btn color="info" @click="next">{{ $t("WORD_NEXT") }}</v-btn>
                </div>
              </div>
            </v-stepper-content>
            <!-- ステップ３ 取り込み開始 -->
            <v-stepper-content step="3">
              <div class="text-center contents_area">
                <div class="text_area">
                  <div class="d-flex justify-space-between">
                    <p class="border_left">{{ $t("TEXT_INPUT_FILE_SETTING_CHECK") }}</p>
                  </div>
                  <v-divider class="mt-1"></v-divider>
                  <div class="d-flex justify-center">
                    <div class="text-center setting_result_area confirm_area mt-1">
                      <v-container>
                        <p class="border_left text-left">{{ $t("PAGE_TITLE_INPUT_FILE_SETTING_CHECK") }}</p>
                        <v-row justify="center">
                          <v-col cols="5">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_FILE_NAME") }}</v-col>
                          <v-col cols="4">{{ fileName }}</v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="5">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_HEADER") }}</v-col>
                          <v-col cols="4">
                            <span v-if="importSetting.header == IMPORT_SETTING_HEADER_WITH">{{ $t("WORD_INPUT_FILE_SETTING_HEADER_SET") }}</span>
                            <span v-else>{{ $t("WORD_INPUT_FILE_SETTING_HEADER_NOT") }}</span>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="5">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_QUOTATION") }}</v-col>
                          <v-col cols="4">
                            <span v-if="importSetting.character == IMPORT_SETTING_CHARACTER_DOUBLE">{{ $t("WORD_INPUT_FILE_SETTING_QUOTATION_DOUBLE") }}</span>
                            <span v-else>{{ $t("WORD_INPUT_FILE_SETTING_QUOTATION_SINGLE") }}</span>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="5">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_POSITION") }}</v-col>
                          <v-col cols="4">
                            <span v-if="importSetting.location == IMPORT_SETTING_LOCATION_NOPLAN">{{ $t("WORD_INPUT_FILE_SETTING_POSTION_UNPLANNED") }}</span>
                            <span v-else-if="importSetting.location == IMPORT_SETTING_LOCATION_ABSOLUTE">{{ $t("WORD_INPUT_FILE_SETTING_POSTION_ASSIGN") }}</span>
                            <span v-else>{{ $t("WORD_INPUT_FILE_SETTING_POSTION_RELATIVE") }}</span>
                            <span v-if="importSetting.isFixedPos == IMPORT_SETTING_FIXED_POS">{{ $t("WORD_INPUT_FILE_SETTING_ISFIXEDPOS") }}</span>
                          </v-col>
                        </v-row>
                        <v-row v-if="importSetting.location == IMPORT_SETTING_LOCATION_ABSOLUTE" justify="center">
                          <v-col cols="5">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_COL_DATE") }}</v-col>
                          <v-col cols="4">{{ importSetting.absoluteCol }}</v-col>
                        </v-row>
                        <v-row v-if="importSetting.location == IMPORT_SETTING_LOCATION_ABSOLUTE" justify="center">
                          <v-col cols="5">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_COL_PLANNED_AREA") }}</v-col>
                          <v-col cols="4">{{ importSetting.absoluteRow }}</v-col>
                        </v-row>
                        <v-row v-if="importSetting.location == IMPORT_SETTING_LOCATION_RELATIVE" justify="center">
                          <v-col cols="5">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_REFERENCE_DATE") }}</v-col>
                          <v-col cols="4">{{importSetting.referenceDate}}</v-col>
                        </v-row>
                        <v-row
                          v-if="importSetting.location == IMPORT_SETTING_LOCATION_RELATIVE"
                          justify="center"
                        >
                          <v-col cols="5">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_RELATIVE_COL_NAME") }}</v-col>
                          <v-col cols="4">{{ relativeColName }}</v-col>
                        </v-row>
                        <v-row v-if="importSetting.location == IMPORT_SETTING_LOCATION_RELATIVE" justify="center">
                          <v-col cols="5">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_RELATIVE_ROW_NAME") }}</v-col>
                          <v-col cols="4">{{ relativeRowName }}</v-col>
                        </v-row>
                        <v-row v-if="hasKeySettings" justify="center">
                          <v-col cols="5">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_SAME_KEY") }}</v-col>
                          <v-col cols="4"> {{ prosessingMethodSameDataExistsName }}</v-col>
                        </v-row>
                        <v-row v-if="option002 && isSelectedSameCardUpdate" justify="center">
                          <v-col cols="5">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_UPDATE_CONFIRM") }}</v-col>
                          <v-col cols="4">{{ getUpdateCardConfirmedStatusName }}</v-col>
                        </v-row>
                      </v-container>
                    </div>
                    <div class="log_area text-center mx-auto mt-4">
                      <div class="text-center">
                        <p class="border_left text-left">{{ $t("PAGE_SUBTITLE_INPUT_FILE_SETTING_ERROR_DETAIL") }}</p>
                        <v-card
                          width="100%"
                          height="38vh"
                          class="text-left scroll pa-2"
                        >
                          <div v-for="item in resultLog" :key="item.id">
                            <div class="alert_text_color">{{ item.message }}</div>
                          </div>
                        </v-card>
                      </div>
                    </div>
                  </div>
                  <div class="mt-6">
                    <v-btn
                      x-large
                      width="56%"
                      color="info"
                      @click="fileImportConfirm"
                    >
                      {{ $t("WORD_INPUT_FILE_START") }}
                    </v-btn>
                  </div>
                </div>
                <div class="button_area text-left">
                  <v-btn @click="stepperPageNum = 2">{{ $t("WORD_BACK") }}</v-btn>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/parts/PageTitle";
import HelpButton from "@/components/parts/HelpButton";
import InputFileSetting from "@/components/parts/InputFileSetting";
import { createNamespacedHelpers } from "vuex";
import * as Util from "@/util/utils.js";
import Swal from "sweetalert2";
import moment from "moment";
import Define from "@/define.js";
import { mixinUtil } from "@/mixins/mixinUtils.js";
const { mapGetters } = createNamespacedHelpers("setting");
const { mapActions } = createNamespacedHelpers("tile");
export default {
  components: {
    PageTitle,
    HelpButton,
    InputFileSetting
  },
  mixins: [mixinUtil],
  data() {
    return {
      resultLog: [],
      dialog: false,
      importFile: null,
      importSetting: {},
      areaList: "",
      stepperPageNum: 1,
      fileName: "",
      alertFlg: false,
      colSelectList: null,
      rowSelectList: null,
      numSelectList: null,
      relativeColName: "",
      relativeRowName: "",
      menu: false,
      selectDate: {
        type: Date,
        default: new Date()
      },

      // ヘッダー行
      IMPORT_SETTING_HEADER_WITH: Define.IMPORT_SETTING_HEADER_WITH,
      IMPORT_SETTING_HEADER_WITHOUT: Define.IMPORT_SETTING_HEADER_WITHOUT,
      // 値の囲い文字
      IMPORT_SETTING_CHARACTER_DOUBLE: Define.IMPORT_SETTING_CHARACTER_DOUBLE,
      IMPORT_SETTING_CHARACTER_SINGLE: Define.IMPORT_SETTING_CHARACTER_SINGLE,
      // 取り込みカードの初期位置
      IMPORT_SETTING_LOCATION_NOPLAN: Define.IMPORT_SETTING_LOCATION_NOPLAN,
      IMPORT_SETTING_LOCATION_ABSOLUTE: Define.IMPORT_SETTING_LOCATION_ABSOLUTE,
      IMPORT_SETTING_LOCATION_RELATIVE: Define.IMPORT_SETTING_LOCATION_RELATIVE,
      // カード位置固定
      IMPORT_SETTING_NOT_FIXED_POS: Define.IMPORT_SETTING_NOT_FIXED_POS,
      IMPORT_SETTING_FIXED_POS: Define.IMPORT_SETTING_FIXED_POS,
      // キーが同一のデータが存在した場合の動作
      IMPORT_SETTING_SAME_KEY_UPDATE: Define.IMPORT_SETTING_SAME_KEY_UPDATE,
      IMPORT_SETTING_SAME_KEY_ERROR: Define.IMPORT_SETTING_SAME_KEY_ERROR,
      IMPORT_SETTING_SAME_KEY_SKIP: Define.IMPORT_SETTING_SAME_KEY_SKIP,
      // ファイル取り込み(更新)時の確定状態の動作
      IMPORT_SETTING_UPDATE_CONFIRM_UNCONFIRM: Define.IMPORT_SETTING_UPDATE_CONFIRM_UNCONFIRM,
      IMPORT_SETTING_UPDATE_CONFIRM_KEEP: Define.IMPORT_SETTING_UPDATE_CONFIRM_KEEP
    };
  },
  computed: {
    ...mapGetters(["getMappingList", "getAreaList", "getMappingCsvList"]),
    listColNum() {
      let array = [];
      for (let i = 1; i <= Define.CSV_COL_LIMIT; i++) {
        array.push({ text: i, value: i });
      }
      return array;
    },
    mappingList() {
      return this.getMappingList;
    },
    hasKeySettings() {
      return Util.hasKeySettings(this.getMappingList);
    },
    option002() {
      let option = this.$store.getters["option/getOption"];
      return option.option002;
    },
    prosessingMethodSameDataExistsName() {
      let ret = "1";
      switch (this.importSetting.prosessingMethodSameDataExists) {
        case "1":
          ret = this.$t("WORD_INPUT_FILE_SETTING_SAME_KEY_UPDATE");
          break;
        case "2":
          ret = this.$t("WORD_INPUT_FILE_SETTING_SAME_KEY_ERROR");
          break;
        case "3":
          ret = this.$t("WORD_INPUT_FILE_SETTING_SAME_KEY_SKIP");
          break;
      }
      return ret;
    },
    isSelectedSameCardUpdate() {
      let ret = false;
      if (!this.hasKeySettings) {
        return ret;
      }
      switch (this.importSetting.prosessingMethodSameDataExists) {
        case "1":
          ret = true;
          break;
        case "2":
        case "3":
          break;
        default:
          break;
      }
      return ret;
    },
    getUpdateCardConfirmedStatusName() {
      let ret = "";
      switch (this.importSetting.updateCardConfirmedStatus) {
        case "1":
          ret = this.$t("WORD_INPUT_FILE_SETTING_UPDATE_CONFIRM_UNCONFIRM");
          break;
        case "2":
          ret = this.$t("WORD_INPUT_FILE_SETTING_UPDATE_CONFIRM_KEEP");
          break;
        default:
          break;
      }
      return ret;
    },
    authFileInput() {
      let user = this.$store.getters["auth/user"];
      return Util.getAuthority(Define.EVENT_FILE_INPUT, user.role);
    },
    pagetitle() {
      let pageObject = this.getPageTitleDescriptionObj(Define.PAGE_CODE_IMPORT);
      return pageObject.title;
    },
    pagedescription() {
      let pageObject = this.getPageTitleDescriptionObj(Define.PAGE_CODE_IMPORT);
      return pageObject.description;
    },
    InputFileSetting() {
      return this.$store.getters["setting/getInputFileSetting"];
    },
    fileHelpDialogData() {
      return this.$t("EXPLANATION_DIALOG.applicationSettingInputFileSettingHelp");
    },
    attentions() {
      return this.$t("ATTENTION_BY_FILE_IMPORT");
    },
    fileNameNotSet() {
      return this.$t("MSG_NO_FILE");
    }
  },
  methods: {
    loadingOpen() {
      this.$store.commit("setIsLoading", true);
    },
    fileUpload() {
      let result = this.getMappingList;
      let alertMainText = "";
      if (!result) {
        alertMainText = this.$t("MSG_IMPORT_ALERT");
        Swal.fire({
          icon: "error",
          html: alertMainText
        });
        return false;
      }
      if (result.length <= 0) {
        alertMainText = this.$t("MSG_IMPORT_ALERT");
        Swal.fire({
          icon: "error",
          html: alertMainText
        });
        return false;
      }
      this.$refs.inputUpload.value = "";
      this.$refs.inputUpload.click();
    },
    fileChange(e) {
      if (e.target.files[0]) {
        this.importFile = e.target.files[0];
        this.fileName = this.importFile.name;
        let checkResult = Util.fileNameCheckCsv(this.fileName);
        this.alertFlg = !checkResult ? true : false;
      }
    },
    fileCheck() {
      let alertMainText = "";
      let alertSubText = "";
      if (!Util.checkNotNull(this.importFile)) {
        alertMainText = this.$t("MSG_ERR_MAIN_FILE");
        alertSubText = this.$t("MSG_ERR_SUB_FILE");
      } else if (!Util.fileNameCheckCsv(this.fileName)) {
        alertMainText = this.$t("MSG_ERR_MAIN_EXTENSION");
        alertSubText = this.$t("MSG_ERR_SUB_EXTENSION");
      }
      // チェックの結果異常が無ければ次へ
      if (alertMainText || alertSubText) {
        Swal.fire({
          title: alertMainText,
          html: alertSubText,
          icon: "error"
        });
      } else {
        this.stepperPageNum = 2;
      }
    },
    next() {
      this.stepperPageNum = 3;
    },
    fileImportConfirm() {
      Swal.fire({
        icon: "question",
        text: this.$t("MSG_IMPORT_CONFIRM"),
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.confirmClickOK();
        }
      });
    },
    async confirmClickOK() {
      // loadingダイアログ表示
      this.$store.commit("setIsLoading", true);
      let mappingList = this.getMappingCsvList;
      this.areaList = this.getAreaList;
      let checkResult = await Util.checkCsvImport(
        this.importFile,
        mappingList,
        this.importSetting,
        this.areaList
      );
      // 事前のCSVファイルデータチェックでエラーが存在する場合はエラーダイアログ及びエラー詳細を表示する
      if (checkResult.array.length !== 0) {
        let list = this.createErrorLogByImportFile(checkResult.array);
        this.resultLog = list;
        // loadingダイアログ非表示
        this.$store.commit("setIsLoading", false);
        Swal.fire({
          title: this.$t("MSG_ERR_MAIN_FILE_IMPORT_FAILED"),
          html: this.$t("MSG_ERR_SUB_FILE_IMPORT_FAILED"),
          icon: "error"
        });
      } else {
        this.resultLog = [];
        this.areaList = this.getAreaList;
        let createTileData = this.setCsvData(
          checkResult.data,
          mappingList,
          this.importSetting,
          this.areaList
        );
        // loadingダイアログ非表示(WebSocket通信に行ける場合はreceiveMessageで非表示する)
        // this.$store.commit("setIsLoading", false);
        this.importCsvData({
          tileData: createTileData,
          isFixedPos: this.importSetting.isFixedPos,
          prosessingMethodSameDataExists: this.importSetting.prosessingMethodSameDataExists,
          updateCardConfirmedStatus: this.importSetting.updateCardConfirmedStatus
        });
      }
    },
    setCsvData(data, mappingInfo, settingInfo, areaList) {
      let tileList = [];
      let tileData = {};
      const array = data;
      for (let i = 0; i < array.length; i++) {
        const dataArray = array[i];
        tileData = {
          pos_col: null,
          pos_row: null,
          order: 0,
          tile_detail_data: [],
          tile_move_history_data: []
        };
        // データマッピング設定を走査してカード詳細データを作成する
        for (let j = 0; j < mappingInfo.length; j++) {
          if (mappingInfo[j].column <= dataArray.length) {
            let data = dataArray[mappingInfo[j].column - 1];
            //数値(小数点有り)のdataの場合は指定した桁数で四捨五入
            if (mappingInfo[j].type === Define.TYPE_FLOAT && Util.checkNotEmpty(data)) {
              data = String(Util.computeRoundNum(data, Define.ROUND_NUM));
            }
            //数値(小数点なし)または日付型の場合、nullに再変換
            //そのまま保存するとnullという文字列で保存されるため
            if (
              mappingInfo[j].type === Define.TYPE_INTEGER ||
              mappingInfo[j].type === Define.TYPE_DATE
            ) {
              data = Util.checkTextIsNull(data);
            }
            // カード詳細データをPUTH
            tileData.tile_detail_data.push({
              mapping_id: mappingInfo[j].mappingId,
              value: data
            });
          }
        }

        // 指定位置の場合、タイル位置の設定
        if (settingInfo.location == Define.IMPORT_SETTING_LOCATION_ABSOLUTE) {
          let posDate = dataArray[this.importSetting.absoluteCol - 1];
          let posPlan = dataArray[this.importSetting.absoluteRow - 1];
          tileData.pos_col = this.getPosCol(posDate);
          tileData.pos_row = this.getPosRow(posPlan, posDate, areaList);
        }

        // カード移動履歴データ作成
        tileData.tile_move_history_data.push({
          history_type: Define.HISTORY_TYPE_MOVED,
          pos_row_before: null,
          pos_col_before: null,
          pos_row_after: this.$store.getters["setting/getAreaName"](
            tileData.pos_row
          ),
          pos_col_after: tileData.pos_col
        });
        tileList.push(tileData);
      }
      return tileList;
    },
    ...mapActions(["importCsvData", "deleteImportTileData"]),
    /**
      日付位置を取得する
      @param {String} posDate - 日付位置として入力したデータ
      @returns {String} - 日付位置
     */
    getPosCol(posDate) {
      return posDate === "" ? null : moment(posDate).format("YYYY-MM-DD");
    },
    /**
      計画エリア位置を取得する
      @param {String} posPlan - 計画エリア位置として入力したデータ
      @param {String} posDate - 日付位置として入力したデータ
      @param {Array} areaList - 計画エリア設定
      @returns {String} - 計画エリア位置
     */
    getPosRow(posPlan, posDate, areaList) {
      return posPlan === "" || posDate === ""
        ? null
        : Util.findAreaData(posPlan, areaList);
    }
  }
};
</script>
<style>
.importfilepage {
  margin: 20px;
  padding-left: 20px;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  background-color: #fff;
  border: solid 1px #aaa;
  overflow: auto;
  white-space: nowrap;
}
.header_color {
  background-color: #eceff1;
}
.content {
  background-color: #eceff1;
}
.text_color {
  color: #fff;
}
.border_color {
  border-color: #fff !important;
}
.stepper_content {
  height: 72vh;
}
.text_area {
  height: 90%;
  overflow: auto;
}
.stepper_width {
  width: 99%;
}
.button_area {
  height: 8%;
}
.contents_area {
  height: 64vh;
}
.areatitle {
  border-left: solid 6px #1976d2ca;
  margin-bottom: 30px;
  padding-left: 8px;
}
.button_center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.alert_text_color {
  color: #e53935;
}
.display_inline {
  display: inline-block;
}
.location_setting_area {
  height: 272px;
}
.border_left {
  border-left: solid 8px #1976d2ca;
  padding-left: 8px;
}
.closebtn {
  position: absolute;
  top: 152px;
  right: 380px;
}
.start_button {
  position: absolute;
  bottom: 0;
}
.setting_result_area {
  height: 80%;
}
.border_left_setting {
  border-left: solid 2px #000000de;
}
.border_left {
  border-left: solid 8px #1976d2ca;
  padding-left: 8px;
}
.log_area {
  width: 48%;
  height: 50%;
}
.confirm_area {
  width: 48%;
}
.scroll {
  overflow-y: scroll;
}
.delete_button {
  color: #fff !important;
  background-color: #ff5252;
  border-color: #ff5252;
}
.linefeed {
  white-space: pre-line;
}
</style>
