<template>
  <div>
    <div v-if="isModal == false" style="display:flex;align-items:center;">
      <div class="subtitle" style="height:22px;">
        {{ $t("PAGE_TITLE_CARD_HISTORY") }}
      </div>
      <HelpButton :helpobject="helpDialogData"></HelpButton>
    </div>
    <v-row no-gutters>
      <v-col lg="7" xl="7">
        <div v-if="isModal == false" style="height:26px;">
          {{ $t("PAGE_SUBTITLE_CARD_HISTORY_LIST") }}
        </div>
        <v-card flat :class="getClass">
          <v-row no-gutters>
            <label class="condition-title">{{ $t("HISTORY_TYPE") }}</label>
            <v-checkbox hideDetails class="checkbox" v-model="historyType" :label="$t('HISTORY_TYPE_REGIST')" value="regist"></v-checkbox>
            <v-checkbox hideDetails class="checkbox" v-model="historyType" :label="$t('HISTORY_TYPE_MOVE')" value="move"></v-checkbox>
            <v-checkbox hideDetails class="checkbox" v-model="historyType" :label="$t('HISTORY_TYPE_CANGE')" value="change"></v-checkbox>
            <v-checkbox hideDetails class="checkbox" v-model="historyType" :label="$t('HISTORY_TYPE_COMPLETE')" value="complete"></v-checkbox>
            <v-checkbox hideDetails class="checkbox" v-model="historyType" :label="$t('HISTORY_TYPE_CONFIRM')" value="confirm"></v-checkbox>
            <v-checkbox hideDetails class="checkbox" v-model="historyType" :label="$t('HISTORY_TYPE_CSV')" value="csv"></v-checkbox>
          </v-row>
          <div>
            <v-data-table
              :headers="historyTableHeaders"
              :items="items"
              item-key="moveHistoryId"
              dense
              class="datatable"
              :footer-props="TABLE_FOOTER_SETTING_LONG"
              @click:row="displayDetail"
            >
              <template v-slot:[`header.sort`]="{ header }">
                <div class="sort">{{ header.sort }}</div>
              </template>
              <!-- ユーザー名 -->
              <template v-slot:[`item.name`]="{ item }">
                <div class="user_name">{{ item.name }}</div>
              </template>
              <!-- 更新日時 -->
              <template v-slot:[`item.date`]="{ item }">
                <div>
                  {{ updateDate(item.updatedAt) }}
                </div>
              </template>
              <!-- 内容 -->
              <template v-slot:[`item.content`]="{ item }">
                <div>{{ getHistoryMsg(item) }}</div>
              </template>
              <!-- 詳細 -->
              <template v-slot:[`item.detail`]="{ item }">
                <div v-if="isDisplayDetailButton(item)">
                  <v-icon small class="mr-2">
                    mdi-arrow-right-thick
                  </v-icon>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
      <v-col lg="5" xl="5">
        <div v-if="isModal == false" style="height:26px;">
          {{ $t("PAGE_SUBTITLE_CARD_HISTORY_DETAIL") }}
        </div>
        <v-card flat :class="getClass">
          <div style="font-weight:bold;" class="detailname">{{ detailName }}</div>
          <div v-for="detail in detailList" :key="detail.moveHistoryId">
            <v-row class="areamargin">
              <v-col lg="3" xl="3">
                <v-card flat class="detailtext">
                  <v-tooltip
                    bottom
                    open-delay="300"
                    color="rgba(60,64,67,0.95)"
                    transition="fade"
                  >
                    <template v-slot:activator="{ on: name }">
                      <v-card-text style="color:black;" class="name" v-on="name">
                        {{ getShortString(detail.name)[0] }}
                        <br />
                        {{ getShortString(detail.name)[1] }}
                      </v-card-text>
                    </template>
                    <label>{{ detail.name }}</label>
                  </v-tooltip>
                </v-card>
              </v-col>
              <v-col lg="4" xl="4">
                <v-text-field dense hide-details class="detailtext" readonly outlined v-model="detail.from"></v-text-field>
              </v-col>
              <v-col lg="1" xl="1">
                <v-card flat>
                  <v-card-text style="color:black;" class="arrow">{{ detail.arrow }}</v-card-text>
                </v-card>
              </v-col>
              <v-col lg="4" xl="4">
                <div v-if="isToOutLine">
                  <v-text-field dense hide-details class="detailtext" readonly outlined v-model="detail.to"></v-text-field>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import moment from "moment";
import HelpButton from "@/components/parts/HelpButton";
import { mixinUtil } from "@/mixins/mixinUtils.js";
export default {
  components: {
    HelpButton
  },
  mixins: [mixinUtil],
  props : {
    isModal: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      detailName: "",
      detailList: [],
      format: "",
      beforeDate: "",
      beforeArea: "",
      afterDate: "",
      afterArea: "",
      moveBefore: "",
      moveAfter: "",
      isToOutLine: false,
      historyType: []
    };
  },
  computed: {
    helpDialogData() {
      return this.$t("EXPLANATION_DIALOG.moveHistoryHelp");
    },
    historyTableHeaders() {
      return this.$t("CARD_DETAIL_HISTORY");
    },
    TABLE_FOOTER_SETTING_LONG() {
      return this.$t("TABLE_FOOTER_SETTING_LONG");
    },
    /**
     * 移動履歴にソート用の要素を追加して返す
     * @returns {Array} result - 移動履歴配列
     */
    items() {
      let list = this.$store.getters["tile/tileMoveHistoryData"];
      if(list == null || list == undefined || list.length == 0){
        return [];
      }

      list = Util.historyDataAddSortItem(list)
      if(this.historyType.length == 0){
        return list;
      }

      let result = [];
      if(this.historyType.includes("regist")){
        result = result.concat(list.filter(item => item.historyType == Define.HISTORY_TYPE_MOVED && item.isFirst));
      }
      if(this.historyType.includes("move")){
        result = result.concat(list.filter(item => item.historyType == Define.HISTORY_TYPE_MOVED && !item.isFirst));
      }
      if(this.historyType.includes("change")){
        result = result.concat(list.filter(item => item.historyType == Define.HISTORY_TYPE_DETAIL_CHANGED));
      }
      if(this.historyType.includes("complete")){
        result = result.concat(list.filter(item => item.historyType == Define.HISTORY_TYPE_COMPLETE));
      }
      if(this.historyType.includes("confirm")){
        result = result.concat(list.filter(item => item.historyType == Define.HISTORY_TYPE_CONFIRMED));
      }
      if(this.historyType.includes("csv")){
        result = result.concat(list.filter(item => item.historyType == Define.HISTORY_TYPE_IMPORT_FILE_UPDATE));
      }
      //itemsをupdatedAtの降順でソートする
      result.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      return result;
    },
    detailChangeList() {
      return this.$store.getters["tile/tileDetailChangeHistoryData"];
    },
    isOpenCardDetail() {
      return this.$store.getters["tile/isOpenCardDetail"];
    },
    isOpenHistory() {
      return this.$store.getters["tile/isOpenHistory"];
    },
    getClass(){
      return this.isModal ? "card-history-short scroll areamargin" : "card-history scroll areamargin";
    }
  },
  watch: {
    detailChangeList: {
      deep: true,
      handler: function() {
        for (let i = 0; i < this.detailChangeList.length; i++) {
          this.detailList.push({
            name: this.detailChangeList[i].paramName,
            from: this.detailChangeList[i].paramBefore,
            arrow: Define.ARROW_RIGHT,
            to: this.detailChangeList[i].paramAfter
          });
        }
      }
    },
    isOpenCardDetail: {
      handler: function() {
        if (!this.isOpenCardDetail) {
          this.detailName = "",
          this.detailList = [];
          this.historyType = [];
        }
      }
    },
    isOpenHistory: {
      handler: function() {
        if (!this.isOpenHistory) {
          this.detailName = "",
          this.detailList = [];
          this.historyType = [];
        }
      }
    },
    items: {
      deep: true,
      handler: function() {
        this.detailName = "";
        this.detailList = [];
      }
    }
  },
  methods: {
    /**
     * 日付を指定のフォーマットの文字列に変換して返す
     * @param {Date} date - 更新履歴日付
     * @returns {String} - 整形後日付の文字列
     */
    updateDate(date) {
      let day = Define.DATE_FORMAT_DAY_SLASH;
      let time = Define.DATE_FORMAT_TIME;
      let format = `${day} - ${time}`;
      return moment
        .utc(date)
        .local()
        .format(format);
    },
    /**
     * 履歴の形式をhistoryTypeによって決定する
     * @param {Object} item - 移動履歴オブジェクト
     * @returns {String} result - 画面表示文字列
     */
    getHistoryMsg(item) {
      let result;
      switch (item.historyType) {
        case Define.HISTORY_TYPE_MOVED:
          if (item.isFirst) {
            result = this.$t("HISTORY_TEXT_REGISTRATION");
          } else {
            result = this.$t("HISTORY_TEXT_MOVE");
          }
          break;
        case Define.HISTORY_TYPE_COMPLETE:
          if (item.flgAfter) {
            result = this.$t("HISTORY_TEXT_COMPLETE");
          } else {
            result = this.$t("HISTORY_TEXT_INCOMPLETE");
          }
          break;
        case Define.HISTORY_TYPE_CONFIRMED:
          if (item.flgAfter) {
            result = this.$t("HISTORY_TEXT_CONFIRM");
          } else {
            result = this.$t("HISTORY_TEXT_UNCONFIRM");
          }
          break;
        case Define.HISTORY_TYPE_DETAIL_CHANGED:
          result = this.$t("HISTORY_TEXT_DETAIL_CHANGE");
          break;
        case Define.HISTORY_TYPE_IMPORT_FILE_UPDATE:
          result = this.$t("HISTORY_TEXT_IMPORT_FILE_UPDATE");
          break;
        default:
          break;
      }
      return result;
    },
    /**
     * 詳細列の矢印の表示/非表示をhistoryTypeによって決定する
     * @param {Object} item - 移動履歴オブジェクト
     * @returns {Booleav} result
     */
    isDisplayDetailButton(item) {
      let result = false;
      switch (item.historyType) {
        case Define.HISTORY_TYPE_MOVED:
        case Define.HISTORY_TYPE_DETAIL_CHANGED:
          result = true;
          break;
        case Define.HISTORY_TYPE_COMPLETE:
        case Define.HISTORY_TYPE_CONFIRMED:
        case Define.HISTORY_TYPE_IMPORT_FILE_UPDATE:
          result = false;
          break;
        default:
          result = false;
          break;
      }
      return result;
    },
    /**
     * カード履歴ごとの詳細データを作成して表示する
     * @param {Object} item - 移動履歴オブジェクト
     * @returns {String} - 詳細データリスト
     */
    displayDetail(item) {
      this.detailList = [];
      switch (item.historyType) {
        case Define.HISTORY_TYPE_MOVED:
          this.format = Define.DATE_FORMAT_DAY_SLASH;
          // 移動前列位置
          this.beforeDate =
            item.posColBefore === Define.IS_DATE_FREE
              ? Define.IS_DATE_FREE
              : moment(item.posColBefore).format(this.format);
          // 移動前行位置
          this.beforeArea =
            item.posRowBefore === Define.IS_NO_SETTINGS
              ? Define.IS_NO_SETTINGS
              : item.posRowBefore;
          // 移動後列位置
          this.afterDate =
            item.posColAfter === Define.IS_DATE_FREE
              ? Define.IS_DATE_FREE
              : moment(item.posColAfter).format(this.format);
          // 移動後行位置
          this.afterArea =
            item.posRowAfter === Define.IS_NO_SETTINGS
              ? Define.IS_NO_SETTINGS
              : item.posRowAfter;
          this.moveBefore = this.makeTextTileArea(this.beforeDate, this.beforeArea);
          this.moveAfter = this.makeTextTileArea(this.afterDate, this.afterArea);
          if (item.isFirst) {
            this.detailName = this.$t("DETAIL_NAME_REGISTRATION");
            this.isToOutLine = false;
            this.detailList.push({
              name: this.$t("DETAIL_TEXT_REGISTRATION_AREA"),
              from: this.moveAfter,
              arrow: "",
              to: ""
            });
          } else {
            this.detailName = this.$t("DETAIL_NAME_MOVED");
            this.isToOutLine = true;
            this.detailList.push({
              name: this.$t("DETAIL_TEXT_MOVE"),
              from: this.moveBefore,
              arrow: Define.ARROW_RIGHT,
              to: this.moveAfter
            });
          }
          break;
        case Define.HISTORY_TYPE_DETAIL_CHANGED:
          this.detailName = this.$t("DETAIL_NAME_CHANGE_DETAIL");
          this.isToOutLine = true;
          this.$store.dispatch("tile/getTileDetailChangeHistoryData", item.moveHistoryId);
          break;
        default:
          this.detailName = "";
          break;
      }
    },
    /**
     * 文字列を改行・省略表示する
     * @param {String} target - 対象文字列
     * @param {Number}} newLineNo - 改行位置
     * @param {Number} lastNo - 最終位置(以下...)
     * @return {Array}} - 結果
     */
    getShortString(target) {
      let newLineNo;
      let lastNo;
      if (this.$i18n.locale == "ja") {
        newLineNo = 10;
        lastNo = 20;
      } else {
        newLineNo = 17;
        lastNo = 22;
      }
      return Util.getShortString(target, newLineNo, lastNo);
    }
  }
};
</script>

<style scoped>
.subtitle {
  border-left: solid 6px #1976d2ca;
  color: #333;
  margin: 4px;
  padding-left: 2px;
}
.card-history {
  height: 740px;
  border: solid 1px #aaa;
}
.card-history-short {
  height: 430px;
  border: solid 1px #aaa;
}
.scroll {
  overflow: auto;
  white-space: nowrap;
}
.areamargin {
  margin: 0 5px 5px 5px;
}
.datatable {
  width: 99%;
  padding: 0 10px;
  right: 0;
}
.sort {
  color: #fff;
  width: 0;
  display: none;
}
.user_name {
  width: 204px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.detailname {
  padding: 15px;
}
.name {
  padding: 0 10px 0 20px;
  font-size: 16px;
}
.arrow {
  padding: 0;
  font-size: 20px;
}
.col-lg-3 {
  padding: 0;
}
.col-lg-4 {
  padding: 0;
}
.condition-title {
  color: #666;
  font-size: 14px;
  font-weight: bold;
  margin: 20px 15px 10px 15px;
}
.checkbox {
  margin-right: 10px;
  margin-bottom: 2px;
}
</style>
