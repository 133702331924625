<template>
  <!-- 検索条件 -->
  <v-card flat rounded>
    <v-form ref="form">
      <div class="pt-0 mt-0">
        <v-row dense>
          <v-col cols="4" class="mx-1">
            <!-- 検索対象エリア -->
            <v-row dense align="center" class="my-1">
              <v-col cols="3" class="condition-title">{{ $t("PAGE_SUBTITLE_CARD_SEARCH_AREA") }}</v-col>
              <v-radio-group class="my-2" hide-details :column="false" v-model="condition.cardArea">
                <v-radio :label="$t('WORD_CARD_SEARCH_UNPLAANED_AREA')" value="free"></v-radio>
                <v-radio class="ml-2" :label="$t('WORD_CARD_SEARCH_PLAANED_AREA')" value="plan"></v-radio>
              </v-radio-group>
            </v-row>
            <!-- 日付 -->
            <v-row dense align="center">
              <v-col cols="3" class="condition-title">{{ $t("PAGE_SUBTITLE_CARD_SEARCH_DATE") }}</v-col>
              <v-col>
                <EditingItem v-model="condition.startDate" :type="TYPE_DATE" :rules="[]" :hideDetails="true" :editable="condition.cardArea=='plan'"></EditingItem>
              </v-col>
              ～
              <v-col>
                <EditingItem v-model="condition.endDate" :type="TYPE_DATE" :rules="[]" :hideDetails="true" :editable="condition.cardArea=='plan'"></EditingItem>
              </v-col>
            </v-row>
            <!-- エリア名 -->
            <v-row dense align="center" class="mt-1">
              <v-col cols="3" class="condition-title">{{ $t("PAGE_SUBTITLE_CARD_SEARCH_AREANAME") }}</v-col>
              <v-select dense outlined hide-details v-model="condition.area" :items="areaItems" class="px-1" :disabled="condition.cardArea!='plan'"></v-select>
            </v-row>
          </v-col>
          <v-col cols="5" class="mx-1">
            <v-row dense align="center">
              <!-- 確定状態 -->
              <v-col cols="6" v-if="option002" >
                <v-row dense align="center">
                  <v-col cols="4" class="condition-title">{{ $t("PAGE_SUBTITLE_CARD_SEARCH_CONFIRM") }}</v-col>
                  <v-checkbox dense hide-details v-model="condition.isConfirmedFlase" :label="$t('WORD_CARD_SEARCH_UNCONFIRM')" class="my-2" :disabled="condition.cardArea!='plan'"></v-checkbox>
                  <v-checkbox dense hide-details v-model="condition.isConfirmedTrue" :label="$t('WORD_CARD_SEARCH_CONFIRM')" class="my-2 ml-2" :disabled="condition.cardArea!='plan'"></v-checkbox>
                </v-row>
              </v-col>
              <!-- 完了状態 -->
              <v-col cols="6">
                <v-row dense align="center">
                  <v-col cols="4" class="condition-title">{{ $t("PAGE_SUBTITLE_CARD_SEARCH_COMPLETE") }}</v-col>
                  <v-checkbox dense hide-details v-model="condition.isCompletedFlase" :label="$t('WORD_CARD_SEARCH_INCOMPLETE')" class="my-2" :disabled="condition.cardArea!='plan'"></v-checkbox>
                  <v-checkbox dense hide-details v-model="condition.isCompletedTrue" :label="$t('WORD_CARD_SEARCH_COMPLETE')" class="my-2 ml-2" :disabled="condition.cardArea!='plan'"></v-checkbox>
                </v-row>
              </v-col>
            </v-row>
            <!-- 詳細項目 -->
            <v-row dense align="center">
              <v-col cols="2" class="condition-title">{{ $t("PAGE_SUBTITLE_CARD_SEARCH_DETAIL") }}</v-col>
              <v-col>
                <v-row dense align="center">
                  <v-select
                    v-model="mapping"
                    class="dropdown"
                    background-color="#fff"
                    dense
                    outlined
                    hide-details
                    :label="$t('WORD_CATEGORY')"
                    :items="dropdown"
                    item-text="name"
                    item-value="mapping_id"
                    return-object
                    @change="condition.detailValue=''"
                  ></v-select>
                  <v-text-field
                    v-model="condition.detailValue"
                    class="detail-value"
                    dense
                    outlined
                    hide-details
                    :rules="[checkString]"
                  ></v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <!-- 作成日時 -->
            <v-row dense align="center">
              <v-col cols="2" class="condition-title">{{ $t("PAGE_SUBTITLE_CARD_SEARCH_CREATEDATE") }}</v-col>
              <v-col>
                <v-row dense align="center">
                  <EditingItem class="mr-0" style="width:140px;" v-model="condition.startCreatedDate" :type="TYPE_DATE" :rules="[]" :hideDetails="true"></EditingItem>
                  <EditingItem class="mr-1" style="width:50px;" v-model="condition.startCreatedTime" :type="TYPE_TIME" :rules="[]" :hideDetails="true"></EditingItem>
                </v-row>
              </v-col>
              ～
              <v-col>
                <v-row dense align="center">
                  <EditingItem class="ml-1" style="width:140px;" v-model="condition.endCreatedDate" :type="TYPE_DATE" :rules="[]" :hideDetails="true"></EditingItem>
                  <EditingItem class="ml-0" style="width:50px;" v-model="condition.endCreatedTime" :type="TYPE_TIME" :rules="[]" :hideDetails="true"></EditingItem>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2" class="mx-5">
            <v-row v-if="option001 || option002" dense align="center" class="mt-1">
              <label class="search-btn-title">{{ $t("PAGE_SUBTITLE_CARD_SEARCH_NORMAL_SEARCH") }}</label>
            </v-row>
            <v-row dense align="center" class="mt-1">
              <v-btn width="202px" class="info" @click="search()">
                <v-icon>mdi-magnify</v-icon>
                {{ $t("WORD_SEARCH") }}
              </v-btn>
            </v-row>
            <v-row v-if="option001 || option002" dense align="center" class="mt-1">
              <label class="search-btn-title">{{ $t("PAGE_SUBTITLE_CARD_SEARCH_ONCLICK_SEARCH") }}</label>
            </v-row>
            <v-row v-if="option001 || option002" dense align="center">
              <v-btn v-if="option002" :width="setOnclickWidth" :class="setOnclickClass" @click="searchOption002()">{{ $t("WORD_SEARCH_UNCONFIRM") }}</v-btn>
              <v-btn v-if="option001" :width="setOnclickWidth" :class="setOnclickClass" @click="searchOption001()">{{ $t("WORD_SEARCH_INCOMPLETE") }}</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import Define from "@/define.js";
import * as Util from "@/util/utils.js";
import Swal from "sweetalert2";
import moment from "moment";
import EditingItem from "@/components/parts/EditingItem";
import { mixinValidator } from "@/mixins/mixinValidator.js";
export default {
  name: "CardSearchDialogSearchCondition",
  components: {
    EditingItem
  },
  mixins: [mixinValidator],
  props: {
    dialog: Boolean
  },
  data() {
    return {
      condition: {},
      TYPE_DATE: Define.TYPE_DATE,
      TYPE_TIME: Define.TYPE_TIME,
      mapping: {}
    };
  },
  computed: {
    areaItems() {
      let items = [
        { text: "", value: "" },
        { text: this.$t("WORD_NOTSET"), value: null }
      ];
      let areas = this.$store.getters["setting/getAreaList"];
      areas = areas.filter(d => d.order != "");
      if (areas.length) {
        areas.sort(function(a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
      }
      for (let area of areas) {
        items.push({ text: area.name, value: area.areaId });
      }
      return items;
    },
    notifyFlgUpdate() {
      return this.$store.getters["notifyFlgUpdate"];
    },
    notifyFlgDelete() {
      return this.$store.getters["notifyFlgDelete"];
    },
    option001() {
      let option = this.$store.getters["option/getOption"];
      return option.option001;
    },
    option002() {
      let option = this.$store.getters["option/getOption"];
      return option.option002;
    },
    setOnclickWidth() {
      if (this.$i18n.locale == "ja") {
        return "100px";
      } else {
        return "123px";
      }
    },
    setOnclickClass() {
      if (this.$i18n.locale == "ja") {
        return "success mt-1 mr-1";
      } else {
        return "success onclick-search-btn-title-en mt-1 mr-1";
      }
    },
    /**
     * 登録したデータマッピングのリストを取得
     */
    dropdown: {
      get() {
        let defaultItem = { mappingId: "", name: "" };
        let mappingList = Util.deepCopy(this.$store.getters["setting/getMappingList"]);
        mappingList.unshift(defaultItem);
        return mappingList;
      }
    }
  },
  watch: {
    dialog: {
      immediate: true,
      handler: function(value) {
        if (value) {
          this.condition = {
            cardArea: "plan",
            startDate: moment().format(Define.DATE_FORMAT_DAY_SLASH_CHECK),
            endDate: "",
            area: "",
            startCreatedDate: "",
            startCreatedTime: "",
            endCreatedDate: "",
            endCreatedTime: "",
            startUpdatedDate: "",
            startUpdatedTime: "",
            endUpdatedDate: "",
            endUpdatedTime: "",
            isCompletedFlase: false,
            isCompletedTrue: false,
            isConfirmedFlase: false,
            isConfirmedTrue: false,
            status: "",
            detailMappingId: "",
            detailValue: ""
          };
          this.mapping = this.dropdown[0];
          this.$emit("condition", this.condition);
          this.$store.dispatch("tile/serchCard", this.condition);
        }
      }
    },
    notifyFlgUpdate: function() {
      if (this.dialog) {
        this.$emit("condition", this.condition);
        this.$store.dispatch("tile/serchCard", this.condition);
      }
    },
    notifyFlgDelete: function() {
      if (this.dialog) {
        this.$emit("condition", this.condition);
        this.$store.dispatch("tile/serchCard", this.condition);
      }
    },
    mapping: {
      immediate: true,
      handler: function(value) {
        this.condition.detailMappingId = value.mappingId;
      }
    }
  },
  methods: {
    search() {
      if (!this.$refs.form.validate()) {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_MAIN_INPUT"),
          text: this.$t("MSG_ERR_SUB_INPUT")
        });
        return;
      }
      if (this.isExistOnlyTime()) {
        Swal.fire({
          icon: "error",
          title: this.$t("MSG_ERR_MAIN_INPUT"),
          text: this.$t("MSG_ERR_SUB_INPUT_ONLYTIME")
        });
        return;
      }
      this.$emit("condition", this.condition);
      this.$store.dispatch("tile/serchCard", this.condition);
    },
    searchOption001() {
      this.condition = {
        cardArea: "plan",
        startDate: moment().add(-30, "days").format(Define.DATE_FORMAT_DAY_SLASH_CHECK),
        endDate: moment().format(Define.DATE_FORMAT_DAY_SLASH_CHECK),
        area: "",
        startCreatedDate: "",
        startCreatedTime: "",
        endCreatedDate: "",
        endCreatedTime: "",
        isCompletedFlase: true,
        isCompletedTrue: false,
        isConfirmedFlase: false,
        isConfirmedTrue: false,
        detailMappingId: "",
        detailValue: ""
      };
      this.mapping = this.dropdown[0];
      this.$emit("condition", this.condition);
      this.$store.dispatch("tile/serchCard", this.condition);
    },
    searchOption002() {
      this.condition = {
        cardArea: "plan",
        cardAreaPlan: true,
        startDate: moment().format(Define.DATE_FORMAT_DAY_SLASH_CHECK),
        endDate: "",
        area: "",
        startCreatedDate: "",
        startCreatedTime: "",
        endCreatedDate: "",
        endCreatedTime: "",
        isCompletedFlase: false,
        isCompletedTrue: false,
        isConfirmedFlase: true,
        isConfirmedTrue: false,
        detailMappingId: "",
        detailValue: ""
      };
      this.mapping = this.dropdown[0];
      this.$emit("condition", this.condition);
      this.$store.dispatch("tile/serchCard", this.condition);
    },
    isExistOnlyTime() {
      return this.condition.startCreatedTime != "" && this.condition.startCreatedDate == "" || this.condition.endCreatedTime != "" && this.condition.endCreatedDate == "";
    },
    checkString(value) {
      return this.isValidString(value);
    }
  }
};
</script>

<style scoped>
.condition-title {
  color: #666;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}
.search-btn-title {
  color: #999;
  font-size: 14px;
  margin-left: 5px;
}
.onclick-search-btn-title-en {
  font-size: 10px;
}
.dropdown {
  max-width: 180px;
}
.detail-value {
  width: auto;
  margin-left: 10px;
}
</style>
